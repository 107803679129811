import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  inject,
} from '@angular/core';
import { DropdownService } from '@core/services/dropdown.service';
import { ButtonDirective } from '@shared/directives/button.directive';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dropdown',
  standalone: true,
  imports: [CommonModule, OverlayModule,ButtonDirective],
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit, OnDestroy {
  // visible = true;
  visible = false;
  @Input({ required: true }) id!: string;
  @Input() listContainerClasses = '';
  @Input() buttonTitle = '';
  @Input() listItemClasses = '';
  @Input() actionList: DropDownModel[] = [];
  private subscription!: Subscription;

  private dropdownService = inject(DropdownService);

  ngOnInit(): void {
    this.subscription = this.dropdownService.dropdownState$.subscribe((id) => {
      if (id !== this.id) {
        this.visible = true;
      }
    });
  }

  toggle() {
    this.visible = !this.visible;
    if (this.visible) {
      this.dropdownService.openDropdown(this.id);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

export interface DropDownModel {
  title: string;
  action: () => void;
  show?: boolean;
}
