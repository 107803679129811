@if (buttonTitle) {
  <button
    (click)="visible = !visible"
    #trigger
    type="button"
    mango-button
  >
    {{ buttonTitle }}
  </button>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="visible"
  >
    <div
      class="absolute right-0 z-20 w-48 rounded-md bg-white shadow-xl"
      [ngClass]="listContainerClasses"
    >
      @for (list of actionList; track list.title) {
        @if (list.show === undefined || list.show === true) {
          <a
            class="block cursor-pointer px-4 py-2 text-sm capitalize text-gray-700 hover:bg-orange-100"
            [ngClass]="listItemClasses"
            type="button"
            (click)="list.action(); toggle()"
            >{{ list.title }}</a
          >
        }
      }
      @if (actionList.length < 1) {
        <a
          type="button"
          (click)="toggle()"
          class="block cursor-pointer px-4 py-2 text-sm capitalize text-gray-700 hover:bg-orange-100"
        >
          No action for this row
        </a>
      }
    </div>
    <div *ngIf="visible" class="app-bg-dismissable" (click)="toggle()"></div>
  </ng-template>
} @else {
  <button
    #trigger
    (click)="visible = !visible"
    type="button"
    class="flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-mango focus:ring-offset-2 focus:ring-offset-gray-100"
    [id]="'menu-button-' + id"
    aria-expanded="true"
    aria-haspopup="true"
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0003 10.8333C10.4606 10.8333 10.8337 10.4602 10.8337 9.99998C10.8337 9.53974 10.4606 9.16665 10.0003 9.16665C9.54009 9.16665 9.16699 9.53974 9.16699 9.99998C9.16699 10.4602 9.54009 10.8333 10.0003 10.8333Z"
        stroke="#98A2B3"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0003 4.99998C10.4606 4.99998 10.8337 4.62688 10.8337 4.16665C10.8337 3.70641 10.4606 3.33331 10.0003 3.33331C9.54009 3.33331 9.16699 3.70641 9.16699 4.16665C9.16699 4.62688 9.54009 4.99998 10.0003 4.99998Z"
        stroke="#98A2B3"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0003 16.6666C10.4606 16.6666 10.8337 16.2935 10.8337 15.8333C10.8337 15.3731 10.4606 15 10.0003 15C9.54009 15 9.16699 15.3731 9.16699 15.8333C9.16699 16.2935 9.54009 16.6666 10.0003 16.6666Z"
        stroke="#98A2B3"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="visible"
  >
    <div
      class="absolute right-0 z-20 w-48 rounded-md bg-white shadow-xl"
      [ngClass]="listContainerClasses"
    >
      @for (list of actionList; track list.title) {
        @if (list.show === undefined || list.show === true) {
          <a
            class="block cursor-pointer px-4 py-2 text-sm capitalize text-gray-700 hover:bg-orange-100"
            [ngClass]="listItemClasses"
            type="button"
            (click)="list.action(); toggle()"
            >{{ list.title }}</a
          >
        }
      }
      @if (actionList.length < 1) {
        <a
          type="button"
          (click)="toggle()"
          class="block cursor-pointer px-4 py-2 text-sm capitalize text-gray-700 hover:bg-orange-100"
        >
          No action for this row
        </a>
      }
    </div>
    <div *ngIf="visible" class="app-bg-dismissable" (click)="toggle()"></div>
  </ng-template>
}
